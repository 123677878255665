import React from "react";
import { Route, Routes } from "react-router-dom";
import MainPage from "./Pages/MainPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Terms from "./Pages/Terms";

const App = () => {

  return (
      <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
      </Routes>
  );
};

export default App;
