import React from 'react';
import appLogo from "../imgs/logo.webp";
import watchImg from "../imgs/watch-img.webp";
import appStoreLogo from "../imgs/appstore-logo.webp"

const MainPage = () => {
    return (
        <div className="main-container">
            <div className="content-div">
                <img className="app-logo" src={appLogo} alt="App Logo"/>
                <div className="image-container">
                    <img className="watch-img" src={watchImg} alt="Watch Img"/>
                </div>
                <div className="cta">
                    <p>Just a Click Away from Amazing Faces!
                    <br className="mobile-break" />
                    Get the app now!</p>
                    <img className="appstore-logo" style={{width: "15vw"}} src={appStoreLogo} alt="Appstore Logo" />
                </div>
                <footer className="footer">
                    <div className="footer-buttons">
                    <a href="/terms" className="footer-button"><i className="fas fa-file-alt"></i> Terms & Conditions</a>
                    <a href="/privacy" className="footer-button"><i className="fas fa-shield-alt"></i> Privacy Policy</a>
                    <a href="mailto:support@purewatchfaces.com" className="footer-button"><i className="fas fa-envelope"></i> Contact Us</a>
                    </div>
                    <div className="footer-info">
                        <p>Pure Watch Faces</p>
                        <p><a href="mailto:support@purewatchfaces.com">support@purewatchfaces.com</a></p>
                        <p className="rights-reserved">All Rights Reserved 2024</p>
                    </div>
                </footer>
                <footer className="footer-mobile">
                    <div className="footer-mobile-buttons">
                        <table>
                            <tbody>
                                <tr className="footer-row">
                                    <td>
                                        <a href="/terms" className="footer-mobile-button">
                                            <i class="fas fa-file-alt"></i> Terms & Conditions
                                        </a>
                                    </td>
                                    <td>
                                        <a href="/privacy" className="footer-mobile-button">
                                            <i class="fas fa-shield-alt"></i> Privacy Policy
                                        </a>
                                    </td>
                                </tr>
                                <tr className="footer-row">
                                    <td>
                                        <a href="mailto:support@purewatchfaces.com" className="footer-mobile-button">
                                            <i class="fas fa-envelope"></i> Contact Us
                                        </a>
                                    </td>
                                    <td>
                                        <div class="footer-mobile-info">
                                            <p>Pure Watch Faces</p>
                                            <p><a href="mailto:support@purewatchfaces.com">support@purewatchfaces.com</a></p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="rights-reserved">All Rights Reserved 2024</p>
                </footer>
            </div>
        </div>
    );
};

export default MainPage;